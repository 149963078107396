/* eslint-disable react/no-unescaped-entities */

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import PostList from '../components/PostList';
import ProjectList from '../components/ProjectList';
import { filenameToSlug, mdxToEntities } from '../helpers';

const HomePage = ({ data }) => {
  const posts = mdxToEntities(data.posts);
  const projects = mdxToEntities(data.projects).map(({ image, ...otherFields }) => ({
    ...otherFields,
  }));

  return (
    <Layout slug={filenameToSlug(__filename)}>
      <div className="homepage-head">
        <div>
          <h1>Hi there, I'm Kostas Karvounis 👋</h1>
          <p>I am a software developer and this is my personal webspace.</p>
          <p>
            I share thoughts, insights and projects hoping that they will make both you and me
            better developers!
          </p>
        </div>
      </div>
      <section className="section">
        <h2>Articles</h2>
        <PostList posts={posts} byYear={false} />
      </section>
      <section className="section">
        <h2>Projects</h2>
        <ProjectList projects={projects} />
      </section>
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage {
    posts: allMdx(
      filter: { frontmatter: { draft: { ne: true }, template: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...Posts
    }
    projects: allMdx(
      filter: { frontmatter: { template: { eq: "project" } } }
      sort: { fields: [frontmatter___order] }
    ) {
      ...Projects
    }
  }
`;
